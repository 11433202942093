import solaris from "./logo.svg";
import spaceship from "./spaceship.png";
import trade from "./trade.png";
import star from "./star.png";
import grid from "./grid.png";
import wheel from "./wheel.png";
import crypto from "./crypto.png";
import planet from "./planet.png";
import tradedesktop from "./tradedesktop.png";
import stardesktop from "./stardesktop.png";
import griddesktop from "./griddesktop.png";
import wheeldesktop from "./wheeldesktop.png";
import cryptodesktop from "./cryptodesktop.png";
import bg4 from "./bg4.png";
import astronaut from "./astronaut.png";
import stake from "./stake.png";
import "./App.css";
import { useEffect, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import desktoplogo from "./desktoplogo.svg";
import logomarktablet from "./Logomarktablet.svg";
import tradetablet from "./tradetablet.png";
import startablet from "./tradestar.png";
import wheeltablet from "./wheeltablet.png";
import cryptotablet from "./cryptotablet.png";
import staketablet from "./staketablet.png";
import gridtablet from "./gridtablet.png";
import mobilelogo from "./mobilelogo.png";
// -

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isDesktop = width >= 1440;

  useEffect(() => {
    if (isDesktop) {
      gsap.registerPlugin(ScrollTrigger);

      let panels = document.querySelectorAll(".panel");
      let tops = Array.from(panels).map((panel) => ScrollTrigger.create({ trigger: panel, start: "top top" }));

      panels.forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () => (panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom"),
          pin: true,
          pinSpacing: false,
        });
      });

      ScrollTrigger.create({
        snap: {
          snapTo: (progress, self) => {
            return gsap.utils.normalize(0, ScrollTrigger.maxScroll(window));
          },
          duration: 0.5,
        },
      });
      return () => {
        // Clean up when the component unmounts
        tops.forEach((top) => top.kill());
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      };
    }
  });

  var b = document.getElementsByTagName("BODY")[0];

  b.addEventListener("mousemove", function (event) {
    parallaxed(event);
  });

  function parallaxed(e) {
    var sensitivity = 24; // Adjust this value to control the sensitivity
    var amountMovedX = (e.clientX * -0.3) / sensitivity;
    var amountMovedY = (e.clientY * -0.3) / sensitivity;
    var elements = document.getElementsByClassName("parallaxed");

    for (var i = 0; i < elements.length; i++) {
      elements[i].style.transform = `translate(${amountMovedX}px, ${amountMovedY}px)`;
    }
  }

  return (
    <div className="font-bricolage bg-dawn">
      {/* Desktop View */}
      <div className="hidden xl:block">
        {/* Navbar */}
        <div className="flex justify-between items-center bg-transparent py-[26px] px-16 sticky top-0 z-50">
          <img src={solaris} className="h-12 w-12" alt="logo" />
          <img src={desktoplogo} className="" alt="logo" />
        </div>
        {/* End of Navbar */}

        {/* Section 1 */}
        <section className="bg-dawn px-16 relative panel z-0 ">
          <div className="opacity-100 z-10 absolute top-32">
            <div className="flex flex-col w-[1037px] pt-[109px]">
              <h1 className="font-medium text-[100px] text-primary leading-none">Trade Perpetuals</h1>
              <h1 className="font-medium text-[100px] text-primary leading-none">like CEXes On-Chain</h1>
              <p className="text-tertiary text-[24px] font-inter leading-none pt-[20px]">
                The best-in-class perpetual DEX with fastest execution, zero gas and support any asset.
              </p>
            </div>
            <button className="btn flex mt-20 ml-32">
              <div className="text">Early Access (soon)</div>
            </button>
          </div>
          <div className=" overflow-visible bg-dawn absolute right-20 top-12" id="container">
            <div className="parallaxed">
              <img src={spaceship} className="w-[722px] opacity-30 " alt="logo" />
            </div>
          </div>
        </section>
        {/* End of Section 1 */}

        {/* Section 2 */}
        <section className="pt-[90px] px-16 panel bg-dawn z-1">
          <span className="text-white text-[72px] flex items-center gap-2 leading-3 pt-[90px]">
            Why <img src={solaris} className="h-12 w-12" alt="logo" />
            <span className="text-primary text-[72px]">Solaris</span>?
          </span>
          <div className="grid grid-cols-4 gap-x-4 pt-[60px]">
            <div className="flex flex-col justify-center items-center ">
              <div className="p-4 bg-tertiary w-full rounded-t-sm">
                <p className="text-[20px] font-semibold leading-[20px] pb-1">Account Abstraction</p>
                <p className="text-[16px] leading-[19.2px] font-light">
                  Forget wallet. Seamless one-click trading without approvals.
                </p>
              </div>
              <img src={tradedesktop} className="rounded-b-sm w-full object-cover h-[328px]" alt="trade" />
            </div>
            <div className="flex flex-col justify-center items-center ">
              <div className="p-4 bg-secondary w-full rounded-t-sm">
                <p className="text-[20px] font-semibold leading-[20px] pb-1">Sub-second Finality</p>
                <p className="text-[16px] leading-[19.2px] font-light">
                  Near-instant settlement. Reliable order execution.
                </p>
              </div>
              <div className=" overflow-hidden flex justify-center items-center rounded-b-sm h-[213px]">
                <img src={star} className="object-cover w-full" alt="star" />
              </div>

              <div className="py-[27.8px] px-4 bg-tertiary w-full mt-4 rounded-sm">
                <p className="text-[20px] font-semibold leading-[20px] pb-1">Low Fees</p>
                <p className="text-[16px] font-light leading-[19.2px]">Zero gas. Competitive trading fees.</p>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center ">
              <div className="p-4 bg-secondary w-full rounded-t-sm">
                <p className="text-[20px] font-semibold leading-[20px] pb-1">Omnichain Perpetuals</p>
                <p className="text-[16px] leading-[19.2px] font-light">
                  Unified liquidity layer across different chains. Zero fragmentation.
                </p>
              </div>
              <div className=" overflow-hidden flex justify-center items-center rounded-b-sm h-[309px]">
                <img src={grid} className="w-full rounded-b-sm object-cover" alt="grid" />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center ">
              <div className="p-4 bg-tertiary w-full rounded-t-sm">
                <p className="text-[20px] font-semibold leading-[20px] pb-1">Deep Liquidity</p>
                <p className="text-[16px] leading-[19.2px] font-light">
                  Best-priced trades with tight spread. Minimal price impact.
                </p>
              </div>
              <div className=" overflow-hidden flex justify-center items-center rounded-b-sm h-[131px]">
                <img src={wheel} className="w-full object-cover" alt="wheel" />
              </div>

              <div className="p-4 bg-secondary w-full mt-4 rounded-t-sm">
                <p className="text-[20px] font-semibold leading-[20px] pb-1">Any asset class</p>
                <p className="text-[16px] leading-[15.2px] font-light">
                  Up to 100x leverage on crypto, forex, RWAs and commodities.
                </p>
              </div>
              <div className=" overflow-hidden flex justify-center items-center rounded-b-sm h-[112px]">
                <img src={crypto} className="w-full object-cover" alt="crypto" />
              </div>
            </div>
          </div>
        </section>
        {/* End of Section 2 */}

        {/* Section 3 */}
        <section className="flex justify-center items-center section panel z-2 bg-dawn">
          <img src={planet} className="w-[480px]" alt="planet" />
        </section>
        {/* End of Section 3 */}

        {/* Section 4 */}
        <section
          className="flex justify-center items-center section panel z-3 bg-dawn"
          style={{
            backgroundImage: `url(${bg4})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="bg-dawn h-[1px] w-screen"></div>
            <div className="bg-dawn h-1 w-screen"></div>
            <div className="bg-dawn h-2 w-screen"></div>
            <div className="bg-dawn h-3 w-screen"></div>
            <div className="bg-dawn h-4 w-screen"></div>
            <div className="bg-dawn h-5 w-screen"></div>
            <div className="bg-dawn h-6 w-screen"></div>

            <div className="text-center py-12 text-primary text-[100px] leading-3 bg-dawn w-full my-2">
              Coming soon in 2025
            </div>

            <div className="bg-dawn h-6 w-screen"></div>
            <div className="bg-dawn h-5 w-screen"></div>
            <div className="bg-dawn h-4 w-screen"></div>
            <div className="bg-dawn h-3 w-screen"></div>
            <div className="bg-dawn h-2 w-screen"></div>
            <div className="bg-dawn h-1 w-screen"></div>
            <div className="bg-dawn h-[1px] w-screen"></div>
          </div>
        </section>
        {/* End of Section 4 */}

        {/* Section 5 */}
        <section className="grid grid-rows-5 px-[290.5px] panel z-10 bg-dawn">
          <div className="row-span-4 flex justify-center items-center">
            <div className="grid grid-cols-2 pt-48">
              <div className="flex flex-col gap-12">
                <p className="text-tertiary text-[32px] font-inter pt-[78.5px]">Learn More</p>
                <div className="flex flex-col">
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-[68px] text-white hover:text-primary leading-10 pb-7 flex items-start gap-1.5 redir"
                  >
                    X
                    <svg
                      className="arrow hidden"
                      width="30"
                      height="30"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1.5H31.5M31.5 1.5V31.5M31.5 1.5L1.5 31.5"
                        stroke="#0cff6d"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <a
                    href="/"
                    className="text-[68px] text-white opacity-20 cursor-not-allowed leading-10 pb-7 hover:text-white"
                    disabled
                  >
                    Telegram
                  </a>
                  <a
                    href="/"
                    className="text-[68px] text-white opacity-20 cursor-not-allowed leading-10 pb-7 hover:text-white"
                    disabled
                  >
                    Discord
                  </a>
                  <a
                    href="/"
                    className="text-[68px] text-white opacity-20 cursor-not-allowed leading-10 hover:text-white"
                    disabled
                  >
                    Medium
                  </a>
                </div>
              </div>
              <div className="w-[480px] ">
                <img src={astronaut} className="opacity-60" alt="astronaut" />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-end">
            <div className="absolute bottom-0">
              <p className="text-tertiary text-[16px] font-inter font-light text-center pb-6">
                © Solaris 2025. All rights reserved.
              </p>
            </div>
          </div>
        </section>

        {/* End of Section 5 */}
      </div>
      {/* End of Desktop View */}

      {/* Tablet View */}
      <div className="hidden md:block xl:hidden">
        {/* Navbar */}
        <div className="flex justify-between items-center bg-transparent py-[26px] px-16 sticky top-0 z-50">
          <img src={solaris} className="h-12 w-12" alt="logo" />
          <h1 className="font-medium text-primary text-5xl">Solaris</h1>
        </div>
        {/* End of Navbar */}

        {/* Section 1 */}
        <section className="bg-dawn px-16 flex items-center panel z-0 ">
          <div className="opacity-100 z-10">
            <div className="flex flex-col">
              <h1 className="font-medium text-[100px] text-primary leading-[110px]">Trade Perpetuals</h1>
              <h1 className="font-medium text-[100px] text-primary leading-[110px]">like CEXes On-Chain</h1>
              <p className="text-tertiary text-[24px] font-inter font-normal leading-[28.8px] pt-[20px]">
                The best-in-class perpetual DEX with fastest execution, zero gas and support any asset.
              </p>
            </div>
            <button className="btn flex mt-28 ml-32">
              <div className="text">Early Access (soon)</div>
            </button>
          </div>
          <div className="opacity-30 z-0 flex items-start">
            <img src={spaceship} className="w-[585px] z-0 absolute right-[60px] top-32" alt="logo" />
          </div>
        </section>
        {/* End of Section 1 */}

        {/* Section 2 */}
        <div className="pt-[90px] px-16 panel bg-dawn z-1">
          <div className=" flex items-center gap-2 ">
            <p className="text-white text-[72px] leading-[79.2px]">Why</p>
            <img src={logomarktablet} className="" alt="logomark tablet" />
            <p className="text-white text-[72px] leading-[79.2px]">?</p>
          </div>

          <div className="grid grid-cols-3 gap-4 pt-[60px]">
            <div className="flex flex-col w-full">
              <div className="bg-tertiary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Account Abstraction</p>
                <p className="font-inter font-light text-[16px] leading-5">
                  Forget wallet. Seamless one-click trading without approvals
                </p>
              </div>
              <div className="overflow-hidden rounded-b-sm">
                <img src={tradetablet} className="w-full" alt="trade" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-secondary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Sub-second Finality</p>
                <p className="font-inter font-light text-[16px] leading-5 ">
                  Near-instant settlement. Reliable order execution.
                </p>
              </div>
              <div className="overflow-hidden rounded-b-sm">
                <img src={startablet} className="w-full" alt="star" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-tertiary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Deep liquidity</p>
                <p className="font-inter font-light text-[16px] leading-5 ">
                  Best-priced trades with tight spread. Minimal price impact.
                </p>
              </div>
              <div className=" overflow-hidden rounded-b-sm">
                <img src={wheeltablet} className="w-full" alt="wheel" />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <div className="bg-secondary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Any asset class</p>
                <p className="font-inter font-light text-[16px] leading-5">
                  Up to 100x leverage on crypto, forex, RWAs and commodities.
                </p>
              </div>
              <div className=" overflow-hidden rounded-b-sm">
                <img src={cryptotablet} className="w-full" alt="crypto" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-tertiary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Low Fees</p>
                <p className="font-inter font-light text-[16px] leading-5">Zero gas. Competitive trading fees.</p>
              </div>
              <div className=" overflow-hidden rounded-b-sm">
                <img src={staketablet} className="w-full" alt="stake" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-secondary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Omnichain Perpetuals</p>
                <p className="font-inter font-light text-[16px] leading-5 ">
                  Unified liquidity layer across different chains. Zero fragmentation.
                </p>
              </div>
              <div className="overflow-hidden rounded-b-sm">
                <img src={gridtablet} className="w-full" alt="grid" />
              </div>
            </div>
          </div>
        </div>
        {/* End of Section 2 */}

        {/* Section 3 */}
        <section className="flex justify-center items-center panel bg-dawn z-2">
          <img src={planet} className="w-[480px]" alt="planet" />
        </section>
        {/* End of Section 3 */}

        {/* Section 4 */}
        <section
          className="flex justify-center items-center panel bg-dawn z-3"
          style={{
            backgroundImage: `url(${bg4})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="bg-dawn h-[1px] w-screen"></div>
            <div className="bg-dawn h-1 w-screen"></div>
            <div className="bg-dawn h-2 w-screen"></div>
            <div className="bg-dawn h-3 w-screen"></div>
            <div className="bg-dawn h-4 w-screen"></div>
            <div className="bg-dawn h-5 w-screen"></div>
            <div className="bg-dawn h-6 w-screen"></div>

            <div className="text-center py-12 text-primary text-[100px] bg-dawn w-full my-2">Coming soon in 2025</div>

            <div className="bg-dawn h-6 w-screen"></div>
            <div className="bg-dawn h-5 w-screen"></div>
            <div className="bg-dawn h-4 w-screen"></div>
            <div className="bg-dawn h-3 w-screen"></div>
            <div className="bg-dawn h-2 w-screen"></div>
            <div className="bg-dawn h-1 w-screen"></div>
            <div className="bg-dawn h-[1px] w-screen"></div>
          </div>
        </section>
        {/* End of Section 4 */}

        {/* Section 5 */}

        <section className="grid grid-rows-8 px-[167.5px] panel z-10 bg-dawn">
          <div className="row-span-6 flex justify-center items-end">
            <div className="grid grid-cols-2">
              <div className="flex flex-col gap-12 pt-[72pt]">
                <p className="text-tertiary text-[32px] font-inter">Learn More</p>
                <div className="flex flex-col">
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-[68px] text-white hover:text-primary leading-10 pb-7 flex items-start gap-1.5 redir"
                  >
                    X
                    <svg
                      className="arrow hidden"
                      width="30"
                      height="30"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 1.5H31.5M31.5 1.5V31.5M31.5 1.5L1.5 31.5"
                        stroke="#0cff6d"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                  <a href="/" className="text-[68px] text-white opacity-20 cursor-not-allowed leading-10 pb-7" disabled>
                    Telegram
                  </a>
                  <a href="/" className="text-[68px] text-white opacity-20 cursor-not-allowed leading-10 pb-7" disabled>
                    Discord
                  </a>
                  <a href="/" className="text-[68px] text-white opacity-20 cursor-not-allowed leading-10" disabled>
                    Medium
                  </a>
                </div>
              </div>
              <div className="pt-[40px]">
                <img src={astronaut} className="opacity-60" alt="astronaut" />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-end w-full row-span-2">
            <div className="bottom-0 flex justify-center pb-6 ">
              <p className="text-tertiary text-[16px] font-inter font-light">© Solaris 2025. All rights reserved.</p>
            </div>
          </div>
        </section>
        {/* End of Section 5 */}
      </div>
      {/* End of Tablet View */}

      {/* Mobile View */}
      <div className="md:hidden">
        {/* Navbar */}
        <div className="flex justify-between items-center bg-transparent py-4 px-6 sticky top-0 z-50">
          <img src={solaris} className="h-6 w-6" alt="logo" />
          <img src={mobilelogo} className="" alt="logo" />
        </div>
        {/* End of Navbar */}

        {/* Section 1 */}
        <div className="bg-dawn flex justify-center items-center mobile-section">
          <img
            src={spaceship}
            className="w-[393px] h-[393px] z-0 absolute flex mx-auto mt-20 opacity-30 top-0"
            alt="logo"
          />
          <div className="opacity-100 z-10 mt-64">
            <div className="flex flex-col px-6">
              <h1 className="font-medium text-[40px] text-primary leading-none">Trade Perpetuals</h1>
              <h1 className="font-medium text-[40px] text-primary leading-none">like CEXes</h1>
              <h1 className="font-medium text-[40px] text-primary leading-none">On-Chain</h1>
              <p className="text-tertiary font-light text-[16px] font-inter leading-none pt-[20px]">
                The best-in-class perpetual DEX with fastest execution, zero gas and support any asset.
              </p>
            </div>
            <button className="btn-mobile flex mt-20">
              <div className="text">Early Access (soon)</div>
            </button>
          </div>
        </div>
        {/* End of Section 1 */}

        {/* Section 2 */}
        <div className="pt-[120px] px-6 ">
          <span className="text-white text-[32px] flex items-center gap-0.5 leading-3">
            Why <img src={solaris} className="h-6 w-6" alt="logo" />
            <span className="text-primary text-[32px]">Solaris</span>?
          </span>

          <div className="grid grid-cols-1 gap-4 pt-[20px]">
            <div className="flex flex-col w-full">
              <div className="bg-tertiary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-3">Account Abstraction</p>
                <p className="font-inter font-light text-[16px] leading-5">
                  Forget wallet. Seamless one-click trading without approvals
                </p>
              </div>
              <div className="h-[256px] overflow-hidden rounded-b-sm">
                <img src={trade} className="w-full" alt="trade" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-secondary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-3">Sub-second Finality</p>
                <p className="font-inter font-light text-[16px] leading-5 ">
                  Near-instant settlement. Reliable order execution.
                </p>
              </div>
              <div className="h-[256px] overflow-hidden rounded-b-sm">
                <img src={star} className="w-full" alt="star" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-tertiary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-3">Deep liquidity</p>
                <p className="font-inter font-light text-[16px] leading-5 ">
                  Best-priced trades with tight spread. Minimal price impact.
                </p>
              </div>
              <div className="h-[256px] overflow-hidden rounded-b-sm">
                <img src={wheel} className="w-full" alt="wheel" />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <div className="bg-secondary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-3">Any asset class</p>
                <p className="font-inter font-light text-[16px] leading-5">
                  Up to 100x leverage on crypto, forex, RWAs and commodities.
                </p>
              </div>
              <div className="h-[256px] overflow-hidden rounded-b-sm">
                <img src={crypto} className="w-full" alt="crypto" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-tertiary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-3">Low Fees</p>
                <p className="font-inter font-light text-[16px] leading-5">Zero gas. Competitive trading fees.</p>
              </div>
              <div className="h-[256px] overflow-hidden rounded-b-sm">
                <img src={stake} className="w-full" alt="stake" />
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="bg-secondary rounded-t-sm px-4 h-[108px]">
                <p className="font-semibold text-[20px] pt-4 leading-5 pb-1">Omnichain Perpetuals</p>
                <p className="font-inter font-light text-[16px] leading-5 ">
                  Unified liquidity layer across different chains. Zero fragmentation.
                </p>
              </div>
              <div className="h-[256px] overflow-hidden rounded-b-sm">
                <img src={grid} className="w-full" alt="grid" />
              </div>
            </div>
          </div>
        </div>
        {/* End of Section 2 */}

        {/* Section 3 */}
        <div className="flex justify-center items-center mobile-section">
          <img src={planet} className="w-[393px]" alt="planet" />
        </div>
        {/* End of Section 3 */}

        {/* Section 4 */}
        <div
          className="flex justify-center items-center mobile-section"
          style={{
            backgroundImage: `url(${bg4})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="bg-dawn h-[1px] w-screen"></div>
            <div className="bg-dawn h-1 w-screen"></div>
            <div className="bg-dawn h-2 w-screen"></div>
            <div className="bg-dawn h-3 w-screen"></div>
            <div className="bg-dawn h-4 w-screen"></div>
            <div className="bg-dawn h-5 w-screen"></div>
            <div className="bg-dawn h-6 w-screen"></div>

            <div className="text-center py-12 text-primary text-[30px] bg-dawn w-full my-2">Coming soon in 2025</div>

            <div className="bg-dawn h-6 w-screen"></div>
            <div className="bg-dawn h-5 w-screen"></div>
            <div className="bg-dawn h-4 w-screen"></div>
            <div className="bg-dawn h-3 w-screen"></div>
            <div className="bg-dawn h-2 w-screen"></div>
            <div className="bg-dawn h-1 w-screen"></div>
            <div className="bg-dawn h-[1px] w-screen"></div>
          </div>
        </div>
        {/* End of Section 4 */}

        {/* Section 5 */}
        <div className="flex justify-center items-center mobile-section">
          <div className="grid grid-cols-1">
            <div className="w-60">
              <img src={astronaut} className="opacity-60" alt="astronaut" />
            </div>
            <div className="flex flex-col gap-2 pt-10">
              <p className="text-tertiary text-[24px] font-inter">Learn More</p>
              <div className="flex flex-col">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[40px] text-white hover:text-primary leading-10 pb-3 flex items-start gap-1.5 redir"
                >
                  X
                  <svg
                    className="arrow hidden"
                    width="30"
                    height="30"
                    viewBox="0 0 50 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 1.5H31.5M31.5 1.5V31.5M31.5 1.5L1.5 31.5"
                      stroke="#0cff6d"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a href="/" className="text-[40px] text-white opacity-20 cursor-not-allowed leading-10 pb-3" disabled>
                  Telegram
                </a>
                <a href="/" className="text-[40px] text-white opacity-20 cursor-not-allowed leading-10 pb-3" disabled>
                  Discord
                </a>
                <a href="/" className="text-[40px] text-white opacity-20 cursor-not-allowed leading-10" disabled>
                  Medium
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-0 flex justify-center pb-6 h-full">
          <p className="text-tertiary text-[14px] font-inter font-light text-center mx-10">
            © Solaris 2025. All rights reserved.
          </p>
        </div>
        {/* End of Section 5 */}
      </div>
      {/* End of Mobile View */}
    </div>
  );
}

export default App;
